<template>
  <a-card :bodyStyle="{ padding: 0 }">
    <template #title>
      <a-form :model="form" layout="inline">
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="form.orderId" placeholder="订单号" />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="form.productName" placeholder="产品" />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="form.vinNo" placeholder="车架号" />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="form.orderStatus" allowClear placeholder="状态">
            <a-select-option v-for="item in orderStatusModes" :key="item.value">{{ item.label }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="form.orderSettlement" allowClear placeholder="结算方式">
            <a-select-option v-for="item in settlementModes" :key="item.value">{{ item.label }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="form.customerName" placeholder="客户" />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="form.officeOrgIds" mode="multiple" allowClear @change="orgChange" placeholder="所属区域">
            <a-select-option v-for="item in orgs" :key="item.id">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-range-picker v-model:value="form.timeLimit" style="width:100%" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" placeholder="推送时间" :showTime="false" />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="form.startAddress" placeholder="始发地">
            <template #prefix>始</template>
          </a-input>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="form.endAddress" placeholder="目的地">
            <template #prefix>终</template>
          </a-input>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="form.salesman" allowClear placeholder="录单员">
          </a-input>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="form.settlementStatus" allowClear placeholder="结算状态">
            <a-select-option v-for="item in whether" :key="item.value"> {{ item.label }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-space>
            <a-button type="primary" @click="searchData">查询</a-button>
            <a-button @click="resetSearch">重置</a-button>
          </a-space>
        </a-form-item>

      </a-form>
    </template>
    <a-table :columns="[sortColumn, ...columns]" :row-key="record => record.id" :data-source="listData" :pagination="pagination" :loading="loading" @change="handleTableChange" size="small">
      <template #sort="{ index }">
        {{ index + 1 }}
      </template>
      <template #vinNo="{ record }">
        <div v-if="record.vinNo">
          <div v-for="(item, index) in record.vinNo.split(',')" :key="index" v-show="record.vinNo.split(',').length <= 3">
            {{
              item }}<span v-show="index < record.vinNo.split(',').length - 1">,</span></div>
          <a-tooltip v-if="record.vinNo.split(',').length > 3">
            <template #title>
              <div>全部小车</div>
              <div v-for="(item, index) in record.vinNo.split(',')" :key="index">
                <div>{{ item }}</div>
              </div>
            </template>
            <div>
              {{ record.vinNo.split(',')[0] }}<span v-show="index < record.vinNo.split(',').length - 1">,</span>
              {{ record.vinNo.split(',')[1] }}<span v-show="index < record.vinNo.split(',').length - 1">,</span>
              {{ record.vinNo.split(',')[2] }}...
            </div>
          </a-tooltip>
        </div>
      </template>
      <template #orderId="{ record }">
        <a-typography-paragraph :copyable="{ text: record.id }">
          {{ record.id }}
        </a-typography-paragraph>
      </template>
      <template #contractAmt="{ record }">
        <div>
          <div>{{ record.truckNum }}辆</div>
          <div>总计：{{ record.contractAmt }}</div>
        </div>

      </template>
      <template #orderStatus="{ record }">
        <span v-if="record.orderStatus != null" :style="{ color: (record.orderStatus.value === 0) ? '#ff9900' : (record.orderStatus.value === 1) ? '#339933' : (record.orderStatus.value === 2) ? '#36a3e7' : (record.orderStatus.value === 9) ? '#19be6b' : '' }">
          {{ record.orderStatus.label }}
          <span v-if="record.orderStatus.value === 0">
            <span v-if="record.isConfirm?.value === 0">(未确认)</span>
            <span v-else>(已确认)</span>
          </span>
          </span>
      </template>
      <template #operation="{ record }">
        <span>
          <a @click="detail(record.id)">详情</a>
          <a-divider type="vertical" />
          <a @click="vehicleDetail(record)">跟踪</a>
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link" @click.prevent>
              更多
              <DownOutlined />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item v-if="record.orderStatus.value === 11">
                  <a-popconfirm title="确定推送? " @confirm="send(record)" style="width:300px;">
                    <a>推送</a>
                  </a-popconfirm>
                </a-menu-item>
                <!--                <a-menu-item v-if="!record.isPush.value">-->
                <!--                  <a @click="edit(record)">修改</a>-->
                <!--                </a-menu-item>-->
                <!--                <a-menu-item v-if="!record.isPush.value">-->
                <!--                  <a-popconfirm title="确定删除?" @confirm="deleteItem(record.id)">-->
                <!--                    <a>删除</a>-->
                <!--                  </a-popconfirm>-->
                <!--                </a-menu-item>-->
                <a-menu-item v-if="record.insurance === 1">
                  <a @click="seeInsurance(record.id)">保险</a>
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm title="确定复制该订单? " @confirm="copyOrderById(record.id)" style="width:300px;">
                    <a>复制</a>
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </span>
      </template>
      <!--      <template #expandedRowRender="{ record }">-->
      <!--        <VehicleTable :orderId="record.id" />-->
      <!--      </template>-->
    </a-table>
  </a-card>
  <insurances ref='insurances' />
</template>
<script>
// import { list as orgList } from '@/api/system/organzition'
import { page, push, del, copyOrder } from '@/api/trans/order'
import { useStore } from 'vuex'
import { getOrgList } from '@/utils/util'

import { toRefs, reactive, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { UpOutlined, DownOutlined } from '@ant-design/icons-vue'
import { message, Form } from 'ant-design-vue'
// import VehicleTable from './VehicleTable.vue'
import insurances from '../insurance/insurance'
import dict from "@/config/dict.json"
export default {
  name: 'internal',
  setup () {
    const router = useRouter()
    const store = useStore()

    const data = reactive({
      whether: [
        { "label": "未结算", "value": 0 },
        { "label": "部分结算", "value": 1 },
        { "label": "已结算", "value": 2 }
      ],
      form: {
        orderId: '',
        vinNo: '',
        orderSettlement: null,
        salesmanId: '',
        timeLimit: [],
        startAddress: '',
        endAddress: '',
        officeOrgIds: []
      },
      mirrorForm: {},
      insurances: null,
      open: false,
      loading: false,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      sortColumn: { title: '序号', slots: { customRender: 'sort' }, width: '3%' },
      columns: [
        { title: '订单号', dataIndex: 'orderId', width: '8%', slots: { customRender: 'orderId' } },
        { title: '小车信息', width: '6%', slots: { customRender: 'vinNo' } },
        { title: '金额', slots: { customRender: 'contractAmt' } },
        { title: '客户名称', dataIndex: 'customerName', width: '6%' },
        { title: '始发地', dataIndex: 'startAddress', width: '10%' },
        { title: '目的地', dataIndex: 'endAddress', width: '10%' },
        { title: '所属区域', dataIndex: 'officeOrgName' },
        { title: '结算方式', dataIndex: 'orderSettlement.label' },
        { title: '结算状态', dataIndex: 'settlementStatus.label' },
        { title: '创建时间', dataIndex: 'orderTime' },
        { title: '录单员', dataIndex: 'salesman' },
        { title: '产品', dataIndex: 'productName' },
        { title: '状态', dataIndex: 'orderStatus', slots: { customRender: 'orderStatus' } },
        { title: '操作', dataIndex: 'operation', width: 200, slots: { customRender: 'operation' } }
      ],
      orgs: [],
      listData: [],
      orderStatusModes: [
        {
          value: 0,
          label: '保存'
        },
        {
          value: 3,
          label: '已接单'
        },
        {
          value: 4,
          label: '待验车'
        },
        {
          value: 5,
          label: '已验车'
        },
        {
          value: 6,
          label: '已拒绝'
        },
        {
          value: 7,
          label: '已取消'
        },
        {
          value: 8,
          label: '待提车'
        },
        {
          value: 11,
          label: '待推送'
        },
        {
          value: 1,
          label: '已推送'
        },
        {
          value: 2,
          label: '运输中'
        },
        {
          value: 9,
          label: '已完成'
        }
      ],
      settlementModes: [
        { label: '货到付款', value: 1 },
        { label: '起运地付清', value: 2 },
        { label: '预付X到付X', value: 3 },
        { label: '月结', value: 4 },
        { label: '记账', value: 5 }
      ],
      vehicleColumns: [],
      vehicleData: []
    })
    const useForm = Form.useForm
    const { resetFields } = useForm(data.form, ref({}))

    const loadData = () => {
      data.loading = true
      page({
        ...data.form,
        pushStartTime: data.form.timeLimit[0],
        pushEndTime: data.form.timeLimit[1],
        current: data.pagination.current,
        size: data.pagination.pageSize
      }).then(res => {
        // console.log(res)  // 主要动态数据
        if (res.code === 10000) {
          data.listData = res.data.records
          data.pagination.current = res.data.current
          data.pagination.pageSize = res.data.size
          data.pagination.total = res.data.total
        }
        data.loading = false
      })
    }
    onMounted(async () => {
      data.orgs = store.state.app.orgTypeList
      if (data.orgs === [] || data.orgs === null || data.orgs === undefined || data.orgs.length <= 0) {
        data.orgs = await getOrgList(1)
      }
      data.mirrorForm = JSON.stringify(data.form)
      loadData()
    })

    const handleTableChange = (pag, filters, sorter) => {
      data.pagination.current = pag.current
      data.pagination.pageSize = pag.pageSize
      loadData()
    }

    const searchData = () => {
      data.pagination.current = 1
      loadData()
    }

    const handleExpandVehicle = (expanded, record) => { }

    const resetSearch = () => {
      data.form = JSON.parse(data.mirrorForm)
      resetFields()
      loadData()
    }

    const add = () => {
      router.push({
        name: 'orderAdd',
        query: { backRoute: router.currentRoute.value.name }
      })
    }

    const seeInsurance = (id) => {
      data.insurances.insuranceDetail(id, true)
    }

    const edit = item => {
      router.push({
        name: 'orderEdit',
        params: { id: item.id, type: 0 },
        query: { backRoute: router.currentRoute.value.name }
      })
    }

    const pushEdit = item => {
      router.push({
        name: 'orderEdit',
        params: { id: item.id, type: 1 },
        query: { backRoute: router.currentRoute.value.name }
      })
    }

    const detail = id => {
      router.push({
        name: 'orderDetail',
        params: {
          id: id
        }
      })
    }

    const vehicleDetail = record => {
      const index = store.getters.tabs.findIndex(item => item.name === 'vehicleDetail')
      if (index >= 0) {
        store.getters.tabs.splice(index, 1)
      }
      router.push({
        name: 'vehicleDetail',
        params: {
          orderId: record.id,
          orderStatus: record.orderStatus.value
        }
      })
    }

    const send = item => {
      push(item.id).then(res => {
        if (res.code === 10000) {
          message.success('推送成功')
          loadData()
        }
      })
    }

    const copyOrderById = id => {
      copyOrder(id).then(res => {
        if (res.code === 10000) {
          message.success('复制成功，新的订单ID为：' + res.data)
          loadData()
        }
      })
    }

    const deleteItem = id => {
      del(id).then(res => {
        if (res.code === 10000) {
          message.success('删除成功')
          loadData()
        }
      })
    }

    const orgChange = (e, v) => {
      data.form.officeOrgIds = e
    }
    return {
      ...toRefs(data),
      handleTableChange,
      searchData,
      resetSearch,
      handleExpandVehicle,
      orgChange,
      add,
      edit,
      pushEdit,
      send,
      deleteItem,
      detail,
      loadData,
      copyOrderById,
      vehicleDetail,
      seeInsurance
    }
  },
  components: {
    UpOutlined,
    DownOutlined,
    insurances
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
